import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import './Header.css'
import logo from '../../assets/images/logo.webp'
import { FaBars, FaTimes } from "react-icons/fa";

export default function Header() {
  const [open, setOpen] = useState(false);

  const handleMenu = () => {
    setOpen((prev) => !prev);
  };

  return (
    <header className='header w-full flex flex-col m-0'>
      <div className="horizontal w-full h-5 bg-gradient-to-r from-main-blue to-main-green m-0"></div>
       <nav className='navbar flex w-full pt-6 pb-6 pl-20 pr-10 md:pr-20 items-center justify-between m-0'>
        <NavLink to='/' className='nav-link mx-auto md:mx-0 sm:mx-auto'>
          <img src={logo} alt='Logo' className='logo h-16' />
        </NavLink>
        <div className='nav-menu flex ml-auto items-center'>
          <ul className="menu-links flex-row gap-4 items-center hidden md:flex text-base font-medium">
            <li className="menu-link">
              <NavLink to='/blog'>AKTUALNOŚCI</NavLink>
            </li>
            <li className="menu-link">
              <NavLink to='/'>STRONA GŁÓWNA</NavLink>
            </li>
            <li className="menu-link">
              <NavLink to='/serwis'>SERWIS</NavLink>
            </li>
            <li className="menu-link">
              <NavLink to='/kontakt'>KONTAKT</NavLink>
            </li>
          </ul>  
          <div className="-mr-2 flex md:hidden">
            <button type="button" onClick={handleMenu}
              className="
              inline-flex 
              items-center 
              justify-center 
              p-2 rounded-md 
              text-black
              hover:text-white 
              hover:bg-main-blue">
              <span className="sr-only">Toggle navigation</span>
              {open === true ? <FaTimes /> : <FaBars />}
            </button>
          </div>
        </div>    
      </nav>
      {open ? (
        <div className={`md:hidden bg-main-green transition-transform duration-300 ease-in-out ${open ? 'translate-y-0' : '-translate-y-full'}`}>
        <div className="ox-2 pt-2 pb-2 space-y-1 sm:px-3 text-center text-white">
          <ul className="block px-3 py-2 rounded-md text-base font-medium">
            <li className="h-10 mb-3 bg-main-blue rounded-md py-2">
            <NavLink onClick={handleMenu} className="w-full h-full flex items-center justify-center" to='/'>STRONA GŁÓWNA</NavLink>
          </li>
          <li className="menu-link h-10 mb-3 bg-main-blue rounded-md py-2">
            <NavLink onClick={handleMenu} className="w-full h-full flex items-center justify-center" to='/serwis'>SERWIS</NavLink>
          </li>
          <li className="menu-link h-10 mb-3 bg-main-blue rounded-md py-2">
            <NavLink onClick={handleMenu} className="w-full h-full flex items-center justify-center" to='/kontakt'>KONTAKT</NavLink>
          </li>
          <li className="menu-link h-10 bg-main-blue rounded-md py-2">
            <NavLink onClick={handleMenu} className="w-full h-full flex items-center justify-center" to='/blog'>BLOG</NavLink>
          </li>
          </ul>
        </div>
      </div>
      ) : null}
    </header>
  )
}