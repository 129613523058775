import React, { useState, useEffect, useRef } from 'react';
import logo from '../../assets/images/logo.webp';
import backgroundImage from '../../assets/images/footer.webp';
import phone from '../../assets/images/phone.webp';
import email from '../../assets/images/mail.webp';

export default function Footer() {
  const [bgImage, setBgImage] = useState(null);
  const footerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setBgImage(backgroundImage);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    const currentFooterRef = footerRef.current;

    if (currentFooterRef) {
      observer.observe(currentFooterRef);
    }

    return () => {
      if (currentFooterRef) {
        observer.unobserve(currentFooterRef);
      }
    };
  }, []);

  return (
    <div ref={footerRef} style={{ position: 'relative', backgroundImage: `url(${bgImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}></div>
      <div className='footer-container flex flex-col items-center justify-center relative text-white text-lg pt-10 pb-5'>
        <img src={logo} alt='Logo' className='footer-logo h-20 object-contain' loading="lazy" />
        <div className='footer-address flex flex-col items-center mt-10'>
            <h2 className='text-xl font-semibold text-gray-300 sm:text-2xl'>ADRES:</h2>
            <p className=''>ul. Łąkowa 11, 35-212 Rzeszów</p>
        </div>
        <div className='footer-open flex flex-col items-center mt-10'>
            <h2 className='text-xl font-semibold text-gray-300 sm:text-2xl'>GODZINY OTWARCIA:</h2>
            <p>Poniedziałek - Piątek</p>
            <p>8:00 - 17:00</p>
        </div>
        <div className='footer-contact flex flex-col items-center mt-10'>
            <div className='flex items-center text-xl sm:text-3xl '>
                <img src={phone} alt='Phone' className='footer-icon h-9 mr-2' loading="lazy"></img>
                <a href="tel:+48694380000" className='flex items-center text-white hover:text-main-green transition-colors duration-200'>
                    694 380 000
                </a>
            </div>
            <div className='flex flex-row items-center align-middle text-xl sm:text-3xl'>
                <img src={email} alt='Email' className='footer-icon h-9 mr-2 mt-5' loading="lazy"></img>
                <a href="mailto:bednarscy.rzeszow@euromaster.pl" className='flex items-center text-white mt-4 hover:text-main-green transition-colors duration-200'>
                    bednarscy.rzeszow@euromaster.pl
                </a>
            </div>
        </div>
        <div className=' border-t border-white opacity-20 mt-10 w-10/12'></div>
        <div className='footer-end flex flex-col items-center mt-4 text-base sm:text-lg'>
            <p className='pb-3'>​Copyright by Euromaster Bednarscy © 2025</p>
            <a href="https://borysbednarski.pl" rel='noreferrer' target="_blank" className='hover:text-main-green transition-colors duration-200'>Realizacja: <b>Borys Bednarski</b></a>
        </div>
      </div>
    </div>
  );
}